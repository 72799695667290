import { Api } from "@/models/class/api.class";
import {
  CreateAssetAdjustmentAssignmentDto,
  DetailAssetAdjustmentDto,
} from "@/models/interface/asset-adjustment";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class AssetAdjustmentService extends HttpClient {
  updateAssetAdjustmentAssignment(
    payload: CreateAssetAdjustmentAssignmentDto,
    id: string
  ): Promise<boolean> {
    return this.put<boolean, CreateAssetAdjustmentAssignmentDto>(
      Api.AssetAdjustmentAssignment + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  detailAssetAdjustment(
    params: RequestQueryParamsModel
  ): Promise<DetailAssetAdjustmentDto[]> {
    return this.get<DetailAssetAdjustmentDto[]>(Api.getAssetAdjustment, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const assetAdjustmentService = new AssetAdjustmentService();
