




























































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { useGeneralJournal } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { logisticServices } from "@/services/logistic.service";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";
import { TRANSFER_TO_ASSET } from "./enums";
import { transferToAssetServices } from "./services/transfer-to-asset.service";
import {
  DataResponseListTransferToAsset,
  ResponseListTransferToAssetParam,
} from "./types/transfer-to-asset.type";

type Row = DataResponseListTransferToAsset & { key: string };

export default Vue.extend({
  name: "TransferToAsset",
  data() {
    this.getProductCode = debounceProcess(this.getProductCode, 200);
    this.getSerialNumber = debounceProcess(this.getSerialNumber, 200);
    this.getProductName = debounceProcess(this.getProductName, 200);
    this.getBranch = debounceProcess(this.getBranch, 200);
    return {
      DEFAULT_DATE_FORMAT,
      form: this.$form.createForm(this, { name: "transferToAsset" }),
      totalData: 0 as number,
      page: 1 as number,
      limit: 10 as number,
      params: {} as RequestQueryParamsModel,
      dataProductCode: [] as ResponseListTransferToAssetParam[],
      dataProductName: [] as ResponseListTransferToAssetParam[],
      dataBranch: [] as DataWarehouseBranch[],
      loadingBranch: false as boolean,
      loadingProductCode: false as boolean,
      loadingSerialNumber: false as boolean,
      loadingFind: false as boolean,
      loadingDownload: false as boolean,
      loadingPrint: false as boolean,
      loadingProductName: false as boolean,
      dataSource: [] as Array<Row>,
      dataSerialNumber: [] as string[],
      productCode: "" as string,
      productName: "" as string,
      columnsTable: [
        {
          title: this.$t("lbl_document_number"),
          dataIndex: "documentNumber",
          key: "documentNumber",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part"),
          dataIndex: "productCode",
          key: "productCode",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_transfer_date"),
          dataIndex: "transferDate",
          key: "transferDate",
          scopedSlots: { customRender: "date" },
        },
        {
          title: this.$t("lbl_qty"),
          dataIndex: "quantity",
          key: "quantity",
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.$t("lbl_cost"),
          dataIndex: "cost",
          key: "cost",
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$t("lbl_merk"),
          dataIndex: "merk",
          key: "merk",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "locationDesc",
          key: "locationDesc",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_journal_number"),
          dataIndex: "journalNumber",
          key: "journalNumber",
          scopedSlots: { customRender: "journal" },
        },
        {
          title: this.$t("lbl_action"),
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      formRules: {
        productCode: {
          label: "lbl_part_number",
          name: "productCode",
          placeholder: "lbl_part_number_placeholder",
          decorator: [
            "productCode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        productName: {
          label: "lbl_part_name",
          name: "productName",
          placeholder: "lbl_part_name_placeholder",
          decorator: [
            "productName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch_placeholder",
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: [
            "serialNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateFrom: {
          label: "lbl_date_from",
          name: "dateFrom",
          placeholder: "lbl_date_from_placeholder",
          decorator: [
            "dateFrom",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateTo: {
          label: "lbl_date_to",
          name: "dateTo",
          placeholder: "lbl_date_to_placeholder",
          decorator: [
            "dateTo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 6 },
        wrapperCol: { span: 10 },
      };
    },
  },
  created() {
    this.getProductCode("");
    this.getProductName("");
    this.getBranch("");
  },
  methods: {
    async getJournal(journalNumber: string): Promise<void> {
      try {
        const { findAll } = useGeneralJournal();
        const response = await findAll({
          search: "name~" + journalNumber,
        });
        const [journal] = response.data;
        if (!journal) throw new Error("Error: journal not found");
        this.$router.push({
          name: "generaljournal.detail",
          params: { id: journal.id },
        });
      } catch (error) {
        this.showNotifError("notif_process_fail");
      }
    },
    responseViewTable(response: Row) {
      localStorage.setItem(
        TRANSFER_TO_ASSET.FORM_DATA,
        JSON.stringify(response)
      );
      this.$router.push(`/logistic/transfer-to-asset/view`);
    },
    handleProductName(productName) {
      this.productName = productName;
      this.form.resetFields(["serialNumber"]);
      this.dataProductName.forEach(dataForeach => {
        if (dataForeach.productName === productName) {
          this.form.setFieldsValue({ productCode: dataForeach.productCode });
          this.dataProductCode = [
            {
              productCode: dataForeach.productCode,
              productName: dataForeach.productName,
              productId: dataForeach.productId,
              serialNumbers: dataForeach.serialNumbers,
            },
          ];
          let counter = 0;
          this.dataSerialNumber = dataForeach.serialNumbers.filter(() => {
            counter++;
            return counter < 10;
          });
        }
      });
    },
    handleProductCode(productCode) {
      this.productCode = productCode;
      this.form.resetFields(["serialNumber"]);
      this.dataProductCode.forEach(dataForeach => {
        if (dataForeach.productCode === productCode) {
          this.form.setFieldsValue({ productName: dataForeach.productName });
          this.dataProductName = [
            {
              productCode: dataForeach.productCode,
              productName: dataForeach.productName,
              productId: dataForeach.productId,
              serialNumbers: dataForeach.serialNumbers,
            },
          ];
          let counter = 0;
          this.dataSerialNumber = dataForeach.serialNumbers.filter(() => {
            counter++;
            return counter < 10;
          });
        }
      });
    },
    handleCreateNew() {
      this.$router.push("/logistic/transfer-to-asset/create");
    },
    getBranch(valueSearch: string) {
      const params = new RequestQueryParams();
      if (valueSearch) {
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      }
      this.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loadingBranch = false));
    },
    getSerialNumber(valueSearch): void {
      if (!this.productCode) {
        this.$notification.error({
          message: "Error",
          description: "Select Part Code First to get Serial Number",
        });
      } else if (valueSearch) {
        this.dataProductCode.forEach(dataForeach => {
          if (dataForeach.productCode === this.productCode) {
            let counter = 0;
            this.dataSerialNumber = dataForeach.serialNumbers.filter(
              dataFilter => {
                if (
                  dataFilter
                    .toLocaleLowerCase()
                    .includes(valueSearch.toLocaleLowerCase())
                )
                  counter++;
                return (
                  dataFilter
                    .toLocaleLowerCase()
                    .includes(valueSearch.toLocaleLowerCase()) && counter < 10
                );
              }
            );
          }
        });
      } else {
        this.dataProductCode.forEach(dataForeach => {
          if (dataForeach.productCode === this.productCode) {
            this.dataSerialNumber = dataForeach.serialNumbers;
          }
        });
      }
    },
    getProductName(valueSearch): void {
      const params: RequestQueryParamsModel = { list: true };
      if (this.productCode) params.code = `${this.productCode}`;
      if (valueSearch) params.name = `${valueSearch}`;

      transferToAssetServices.getListTransferToAssetParam(params).then(data => {
        this.dataProductName = data;
      });
    },
    getProductCode(valueSearch): void {
      const params: RequestQueryParamsModel = { list: true };

      if (this.productName) params.name = `${this.productName}`;
      if (valueSearch) params.code = `${valueSearch}`;

      transferToAssetServices.getListTransferToAssetParam(params).then(data => {
        this.dataProductCode = data;
      });
    },
    handleDownload(): void {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.params.params = this.checkParams(res);
        this.loadingDownload = true;
        transferToAssetServices
          .downloadTransferToAsset(this.params)
          .then(data => {
            if (data) {
              const url = window.URL.createObjectURL(new Blob([data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "reports_transfer_to_asset.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loadingDownload = false));
      });
    },
    handlePrint(): void {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.params.params = this.checkParams(res);
        this.loadingPrint = true;
        transferToAssetServices
          .printTransferToAsset(this.params)
          .then(data => {
            if (data) {
              const url = window.URL.createObjectURL(new Blob([data]));
              printJs(url);
            }
          })
          .finally(() => (this.loadingPrint = false));
      });
    },
    assignSearch(key, value, and): string {
      if (key === "productCode" && value)
        return and + `product.code~*${value}*`;
      else if (key === "productName" && value)
        return and + `product.name~*${value}*`;
      else if (key === "serialNumber" && value)
        return and + `serialNumber~*${value}*`;
      else if (key === "branch" && value)
        return (
          and +
          `logisticWarehouseLocation.warehouse.branchWarehouse.secureId~${value}`
        );
      else if (key === "dateFrom" && value)
        return (
          and +
          `transferDate>=${moment(value)
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()}`
        );
      else if (key === "dateTo" && value)
        return (
          and +
          `transferDate<=${moment(value)
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()}`
        );
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(
        `,${
          this.dataBranch.find(dataFind => dataFind.id === res["branch"])?.name
        }`
      );
      params += this.checkValue(`,${res["productCode"]}`);
      params += this.checkValue(`,${res["productName"]}`);
      params += this.checkValue(`,${res["serialNumber"]}`);
      params += this.checkValue(
        res["dateFrom"]
          ? `,${moment(res["dateFrom"]).format("DD-MMM-yyyy")}`
          : null
      );
      params += this.checkValue(
        res["dateTo"] ? `,${moment(res["dateTo"]).format("DD-MMM-yyyy")}` : null
      );

      return params;
    },
    handleFind(): void {
      this.form.validateFields((err, res) => {
        if (err) return;
        const params = new RequestQueryParams(
          "",
          this.page - 1,
          "transferDate:desc",
          this.limit
        );
        params.search = this.dynamicSearch(res);
        this.loadingFind = true;
        transferToAssetServices
          .getListTransferToAsset(params)
          .then(response => {
            this.totalData = response.totalElements;
            this.params = params;
            this.params.params = this.checkParams(res);
            this.dataSource = response.data.map(dataMap => {
              return {
                ...dataMap,
                key: dataMap.id,
              };
            });
          })
          .finally(() => (this.loadingFind = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    resetForm(): void {
      this.form.resetFields();
    },
    onChangeTable(pagination: {
      total: number;
      current: number;
      pageSize: number;
    }): void {
      this.page = pagination.current;
      if (this.limit !== pagination.pageSize) {
        this.page = 1;
      }

      this.limit = pagination.pageSize;
      this.handleFind();
    },
  },
});
